const timezoneList = [
  {
    "group": "US (Common)",
    "zones": [
      { "name": "Puerto Rico (Atlantic)", "value": "America/Puerto_Rico" },
      { "name": "New York (Eastern)", "value": "America/New_York" },
      { "name": "Chicago (Central)", "value": "America/Chicago" },
      { "name": "Denver (Mountain)", "value": "America/Denver" },
      { "name": "Phoenix (MST)", "value": "America/Phoenix" },
      { "name": "Los Angeles (Pacific)", "value": "America/Los_Angeles" },
      { "name": "Anchorage (Alaska)", "value": "America/Anchorage" },
      { "name": "Honolulu (Hawaii)", "value": "Pacific/Honolulu" },
    ],
  },
  {
    "group": "America",
    "zones": [
      { "name": "Adak", "value": "America/Adak" },
      { "name": "Anchorage", "value": "America/Anchorage" },
      { "name": "Anguilla", "value": "America/Anguilla" },
      { "name": "Antigua", "value": "America/Antigua" },
      { "name": "Araguaina", "value": "America/Araguaina" },
      { "name": "Argentina - Buenos Aires", "value": "America/Argentina/Buenos_Aires" },
      { "name": "Argentina - Catamarca", "value": "America/Argentina/Catamarca" },
      { "name": "Argentina - ComodRivadavia", "value": "America/Argentina/ComodRivadavia" },
      { "name": "Argentina - Cordoba", "value": "America/Argentina/Cordoba" },
      { "name": "Argentina - Jujuy", "value": "America/Argentina/Jujuy" },
      { "name": "Argentina - La Rioja", "value": "America/Argentina/La_Rioja" },
      { "name": "Argentina - Mendoza", "value": "America/Argentina/Mendoza" },
      { "name": "Argentina - Rio Gallegos", "value": "America/Argentina/Rio_Gallegos" },
      { "name": "Argentina - Salta", "value": "America/Argentina/Salta" },
      { "name": "Argentina - San Juan", "value": "America/Argentina/San_Juan" },
      { "name": "Argentina - San Luis", "value": "America/Argentina/San_Luis" },
      { "name": "Argentina - Tucuman", "value": "America/Argentina/Tucuman" },
      { "name": "Argentina - Ushuaia", "value": "America/Argentina/Ushuaia" },
      { "name": "Aruba", "value": "America/Aruba" },
      { "name": "Asuncion", "value": "America/Asuncion" },
      { "name": "Atikokan", "value": "America/Atikokan" },
      { "name": "Atka", "value": "America/Atka" },
      { "name": "Bahia", "value": "America/Bahia" },
      { "name": "Barbados", "value": "America/Barbados" },
      { "name": "Belem", "value": "America/Belem" },
      { "name": "Belize", "value": "America/Belize" },
      { "name": "Blanc-Sablon", "value": "America/Blanc-Sablon" },
      { "name": "Boa Vista", "value": "America/Boa_Vista" },
      { "name": "Bogota", "value": "America/Bogota" },
      { "name": "Boise", "value": "America/Boise" },
      { "name": "Buenos Aires", "value": "America/Buenos_Aires" },
      { "name": "Cambridge Bay", "value": "America/Cambridge_Bay" },
      { "name": "Campo Grande", "value": "America/Campo_Grande" },
      { "name": "Cancun", "value": "America/Cancun" },
      { "name": "Caracas", "value": "America/Caracas" },
      { "name": "Catamarca", "value": "America/Catamarca" },
      { "name": "Cayenne", "value": "America/Cayenne" },
      { "name": "Cayman", "value": "America/Cayman" },
      { "name": "Chicago", "value": "America/Chicago" },
      { "name": "Chihuahua", "value": "America/Chihuahua" },
      { "name": "Coral Harbour", "value": "America/Coral_Harbour" },
      { "name": "Cordoba", "value": "America/Cordoba" },
      { "name": "Costa Rica", "value": "America/Costa_Rica" },
      { "name": "Cuiaba", "value": "America/Cuiaba" },
      { "name": "Curacao", "value": "America/Curacao" },
      { "name": "Danmarkshavn", "value": "America/Danmarkshavn" },
      { "name": "Dawson", "value": "America/Dawson" },
      { "name": "Dawson Creek", "value": "America/Dawson_Creek" },
      { "name": "Denver", "value": "America/Denver" },
      { "name": "Detroit", "value": "America/Detroit" },
      { "name": "Dominica", "value": "America/Dominica" },
      { "name": "Edmonton", "value": "America/Edmonton" },
      { "name": "Eirunepe", "value": "America/Eirunepe" },
      { "name": "El Salvador", "value": "America/El_Salvador" },
      { "name": "Ensenada", "value": "America/Ensenada" },
      { "name": "Fortaleza", "value": "America/Fortaleza" },
      { "name": "Fort Wayne", "value": "America/Fort_Wayne" },
      { "name": "Glace Bay", "value": "America/Glace_Bay" },
      { "name": "Godthab", "value": "America/Godthab" },
      { "name": "Goose Bay", "value": "America/Goose_Bay" },
      { "name": "Grand Turk", "value": "America/Grand_Turk" },
      { "name": "Grenada", "value": "America/Grenada" },
      { "name": "Guadeloupe", "value": "America/Guadeloupe" },
      { "name": "Guatemala", "value": "America/Guatemala" },
      { "name": "Guayaquil", "value": "America/Guayaquil" },
      { "name": "Guyana", "value": "America/Guyana" },
      { "name": "Halifax", "value": "America/Halifax" },
      { "name": "Havana", "value": "America/Havana" },
      { "name": "Hermosillo", "value": "America/Hermosillo" },
      { "name": "Indiana - Indianapolis", "value": "America/Indiana/Indianapolis" },
      { "name": "Indiana - Knox", "value": "America/Indiana/Knox" },
      { "name": "Indiana - Marengo", "value": "America/Indiana/Marengo" },
      { "name": "Indiana - Petersburg", "value": "America/Indiana/Petersburg" },
      { "name": "Indiana - Tell City", "value": "America/Indiana/Tell_City" },
      { "name": "Indiana - Vevay", "value": "America/Indiana/Vevay" },
      { "name": "Indiana - Vincennes", "value": "America/Indiana/Vincennes" },
      { "name": "Indiana - Winamac", "value": "America/Indiana/Winamac" },
      { "name": "Indianapolis", "value": "America/Indianapolis" },
      { "name": "Inuvik", "value": "America/Inuvik" },
      { "name": "Iqaluit", "value": "America/Iqaluit" },
      { "name": "Jamaica", "value": "America/Jamaica" },
      { "name": "Jujuy", "value": "America/Jujuy" },
      { "name": "Juneau", "value": "America/Juneau" },
      { "name": "Kentucky - Louisville", "value": "America/Kentucky/Louisville" },
      { "name": "Kentucky - Monticello", "value": "America/Kentucky/Monticello" },
      { "name": "Knox IN", "value": "America/Knox_IN" },
      { "name": "La Paz", "value": "America/La_Paz" },
      { "name": "Lima", "value": "America/Lima" },
      { "name": "Los Angeles", "value": "America/Los_Angeles" },
      { "name": "Louisville", "value": "America/Louisville" },
      { "name": "Maceio", "value": "America/Maceio" },
      { "name": "Managua", "value": "America/Managua" },
      { "name": "Manaus", "value": "America/Manaus" },
      { "name": "Marigot", "value": "America/Marigot" },
      { "name": "Martinique", "value": "America/Martinique" },
      { "name": "Matamoros", "value": "America/Matamoros" },
      { "name": "Mazatlan", "value": "America/Mazatlan" },
      { "name": "Mendoza", "value": "America/Mendoza" },
      { "name": "Menominee", "value": "America/Menominee" },
      { "name": "Merida", "value": "America/Merida" },
      { "name": "Mexico City", "value": "America/Mexico_City" },
      { "name": "Miquelon", "value": "America/Miquelon" },
      { "name": "Moncton", "value": "America/Moncton" },
      { "name": "Monterrey", "value": "America/Monterrey" },
      { "name": "Montevideo", "value": "America/Montevideo" },
      { "name": "Montreal", "value": "America/Montreal" },
      { "name": "Montserrat", "value": "America/Montserrat" },
      { "name": "Nassau", "value": "America/Nassau" },
      { "name": "New York", "value": "America/New_York" },
      { "name": "Nipigon", "value": "America/Nipigon" },
      { "name": "Nome", "value": "America/Nome" },
      { "name": "Noronha", "value": "America/Noronha" },
      { "name": "North Dakota - Center", "value": "America/North_Dakota/Center" },
      { "name": "North Dakota - New Salem", "value": "America/North_Dakota/New_Salem" },
      { "name": "Ojinaga", "value": "America/Ojinaga" },
      { "name": "Panama", "value": "America/Panama" },
      { "name": "Pangnirtung", "value": "America/Pangnirtung" },
      { "name": "Paramaribo", "value": "America/Paramaribo" },
      { "name": "Phoenix", "value": "America/Phoenix" },
      { "name": "Port-au-Prince", "value": "America/Port-au-Prince" },
      { "name": "Porto Acre", "value": "America/Porto_Acre" },
      { "name": "Port of Spain", "value": "America/Port_of_Spain" },
      { "name": "Porto Velho", "value": "America/Porto_Velho" },
      { "name": "Puerto Rico", "value": "America/Puerto_Rico" },
      { "name": "Rainy River", "value": "America/Rainy_River" },
      { "name": "Rankin Inlet", "value": "America/Rankin_Inlet" },
      { "name": "Recife", "value": "America/Recife" },
      { "name": "Regina", "value": "America/Regina" },
      { "name": "Resolute", "value": "America/Resolute" },
      { "name": "Rio Branco", "value": "America/Rio_Branco" },
      { "name": "Rosario", "value": "America/Rosario" },
      { "name": "Santa Isabel", "value": "America/Santa_Isabel" },
      { "name": "Santarem", "value": "America/Santarem" },
      { "name": "Santiago", "value": "America/Santiago" },
      { "name": "Santo Domingo", "value": "America/Santo_Domingo" },
      { "name": "Sao Paulo", "value": "America/Sao_Paulo" },
      { "name": "Scoresbysund", "value": "America/Scoresbysund" },
      { "name": "Shiprock", "value": "America/Shiprock" },
      { "name": "St Barthelemy", "value": "America/St_Barthelemy" },
      { "name": "St Johns", "value": "America/St_Johns" },
      { "name": "St Kitts", "value": "America/St_Kitts" },
      { "name": "St Lucia", "value": "America/St_Lucia" },
      { "name": "St Thomas", "value": "America/St_Thomas" },
      { "name": "St Vincent", "value": "America/St_Vincent" },
      { "name": "Swift Current", "value": "America/Swift_Current" },
      { "name": "Tegucigalpa", "value": "America/Tegucigalpa" },
      { "name": "Thule", "value": "America/Thule" },
      { "name": "Thunder Bay", "value": "America/Thunder_Bay" },
      { "name": "Tijuana", "value": "America/Tijuana" },
      { "name": "Toronto", "value": "America/Toronto" },
      { "name": "Tortola", "value": "America/Tortola" },
      { "name": "Vancouver", "value": "America/Vancouver" },
      { "name": "Virgin", "value": "America/Virgin" },
      { "name": "Whitehorse", "value": "America/Whitehorse" },
      { "name": "Winnipeg", "value": "America/Winnipeg" },
      { "name": "Yakutat", "value": "America/Yakutat" },
      { "name": "Yellowknife", "value": "America/Yellowknife" },
    ],
  },
  {
    "group": "Europe",
    "zones": [
      { "name": "Amsterdam", "value": "Europe/Amsterdam" },
      { "name": "Andorra", "value": "Europe/Andorra" },
      { "name": "Athens", "value": "Europe/Athens" },
      { "name": "Belfast", "value": "Europe/Belfast" },
      { "name": "Belgrade", "value": "Europe/Belgrade" },
      { "name": "Berlin", "value": "Europe/Berlin" },
      { "name": "Bratislava", "value": "Europe/Bratislava" },
      { "name": "Brussels", "value": "Europe/Brussels" },
      { "name": "Bucharest", "value": "Europe/Bucharest" },
      { "name": "Budapest", "value": "Europe/Budapest" },
      { "name": "Chisinau", "value": "Europe/Chisinau" },
      { "name": "Copenhagen", "value": "Europe/Copenhagen" },
      { "name": "Dublin", "value": "Europe/Dublin" },
      { "name": "Gibraltar", "value": "Europe/Gibraltar" },
      { "name": "Guernsey", "value": "Europe/Guernsey" },
      { "name": "Helsinki", "value": "Europe/Helsinki" },
      { "name": "Isle of Man", "value": "Europe/Isle_of_Man" },
      { "name": "Istanbul", "value": "Europe/Istanbul" },
      { "name": "Jersey", "value": "Europe/Jersey" },
      { "name": "Kaliningrad", "value": "Europe/Kaliningrad" },
      { "name": "Kiev", "value": "Europe/Kiev" },
      { "name": "Lisbon", "value": "Europe/Lisbon" },
      { "name": "Ljubljana", "value": "Europe/Ljubljana" },
      { "name": "London", "value": "Europe/London" },
      { "name": "Luxembourg", "value": "Europe/Luxembourg" },
      { "name": "Madrid", "value": "Europe/Madrid" },
      { "name": "Malta", "value": "Europe/Malta" },
      { "name": "Mariehamn", "value": "Europe/Mariehamn" },
      { "name": "Minsk", "value": "Europe/Minsk" },
      { "name": "Monaco", "value": "Europe/Monaco" },
      { "name": "Moscow", "value": "Europe/Moscow" },
      { "name": "Nicosia", "value": "Europe/Nicosia" },
      { "name": "Oslo", "value": "Europe/Oslo" },
      { "name": "Paris", "value": "Europe/Paris" },
      { "name": "Podgorica", "value": "Europe/Podgorica" },
      { "name": "Prague", "value": "Europe/Prague" },
      { "name": "Riga", "value": "Europe/Riga" },
      { "name": "Rome", "value": "Europe/Rome" },
      { "name": "Samara", "value": "Europe/Samara" },
      { "name": "San Marino", "value": "Europe/San_Marino" },
      { "name": "Sarajevo", "value": "Europe/Sarajevo" },
      { "name": "Simferopol", "value": "Europe/Simferopol" },
      { "name": "Skopje", "value": "Europe/Skopje" },
      { "name": "Sofia", "value": "Europe/Sofia" },
      { "name": "Stockholm", "value": "Europe/Stockholm" },
      { "name": "Tallinn", "value": "Europe/Tallinn" },
      { "name": "Tirane", "value": "Europe/Tirane" },
      { "name": "Tiraspol", "value": "Europe/Tiraspol" },
      { "name": "Uzhgorod", "value": "Europe/Uzhgorod" },
      { "name": "Vaduz", "value": "Europe/Vaduz" },
      { "name": "Vatican", "value": "Europe/Vatican" },
      { "name": "Vienna", "value": "Europe/Vienna" },
      { "name": "Vilnius", "value": "Europe/Vilnius" },
      { "name": "Volgograd", "value": "Europe/Volgograd" },
      { "name": "Warsaw", "value": "Europe/Warsaw" },
      { "name": "Zagreb", "value": "Europe/Zagreb" },
      { "name": "Zaporozhye", "value": "Europe/Zaporozhye" },
      { "name": "Zurich", "value": "Europe/Zurich" },
    ],
  },
  {
    "group": "Asia",
    "zones": [
      { "name": "Aden", "value": "Asia/Aden" },
      { "name": "Almaty", "value": "Asia/Almaty" },
      { "name": "Amman", "value": "Asia/Amman" },
      { "name": "Anadyr", "value": "Asia/Anadyr" },
      { "name": "Aqtau", "value": "Asia/Aqtau" },
      { "name": "Aqtobe", "value": "Asia/Aqtobe" },
      { "name": "Ashgabat", "value": "Asia/Ashgabat" },
      { "name": "Ashkhabad", "value": "Asia/Ashkhabad" },
      { "name": "Baghdad", "value": "Asia/Baghdad" },
      { "name": "Bahrain", "value": "Asia/Bahrain" },
      { "name": "Baku", "value": "Asia/Baku" },
      { "name": "Bangkok", "value": "Asia/Bangkok" },
      { "name": "Beirut", "value": "Asia/Beirut" },
      { "name": "Bishkek", "value": "Asia/Bishkek" },
      { "name": "Brunei", "value": "Asia/Brunei" },
      { "name": "Calcutta", "value": "Asia/Calcutta" },
      { "name": "Choibalsan", "value": "Asia/Choibalsan" },
      { "name": "Chongqing", "value": "Asia/Chongqing" },
      { "name": "Chungking", "value": "Asia/Chungking" },
      { "name": "Colombo", "value": "Asia/Colombo" },
      { "name": "Dacca", "value": "Asia/Dacca" },
      { "name": "Damascus", "value": "Asia/Damascus" },
      { "name": "Dhaka", "value": "Asia/Dhaka" },
      { "name": "Dili", "value": "Asia/Dili" },
      { "name": "Dubai", "value": "Asia/Dubai" },
      { "name": "Dushanbe", "value": "Asia/Dushanbe" },
      { "name": "Gaza", "value": "Asia/Gaza" },
      { "name": "Harbin", "value": "Asia/Harbin" },
      { "name": "Ho Chi Minh", "value": "Asia/Ho_Chi_Minh" },
      { "name": "Hong Kong", "value": "Asia/Hong_Kong" },
      { "name": "Hovd", "value": "Asia/Hovd" },
      { "name": "Irkutsk", "value": "Asia/Irkutsk" },
      { "name": "Istanbul", "value": "Asia/Istanbul" },
      { "name": "Jakarta", "value": "Asia/Jakarta" },
      { "name": "Jayapura", "value": "Asia/Jayapura" },
      { "name": "Jerusalem", "value": "Asia/Jerusalem" },
      { "name": "Kabul", "value": "Asia/Kabul" },
      { "name": "Kamchatka", "value": "Asia/Kamchatka" },
      { "name": "Karachi", "value": "Asia/Karachi" },
      { "name": "Kashgar", "value": "Asia/Kashgar" },
      { "name": "Kathmandu", "value": "Asia/Kathmandu" },
      { "name": "Katmandu", "value": "Asia/Katmandu" },
      { "name": "Kolkata", "value": "Asia/Kolkata" },
      { "name": "Krasnoyarsk", "value": "Asia/Krasnoyarsk" },
      { "name": "Kuala Lumpur", "value": "Asia/Kuala_Lumpur" },
      { "name": "Kuching", "value": "Asia/Kuching" },
      { "name": "Kuwait", "value": "Asia/Kuwait" },
      { "name": "Macao", "value": "Asia/Macao" },
      { "name": "Macau", "value": "Asia/Macau" },
      { "name": "Magadan", "value": "Asia/Magadan" },
      { "name": "Makassar", "value": "Asia/Makassar" },
      { "name": "Manila", "value": "Asia/Manila" },
      { "name": "Muscat", "value": "Asia/Muscat" },
      { "name": "Nicosia", "value": "Asia/Nicosia" },
      { "name": "Novokuznetsk", "value": "Asia/Novokuznetsk" },
      { "name": "Novosibirsk", "value": "Asia/Novosibirsk" },
      { "name": "Omsk", "value": "Asia/Omsk" },
      { "name": "Oral", "value": "Asia/Oral" },
      { "name": "Phnom Penh", "value": "Asia/Phnom_Penh" },
      { "name": "Pontianak", "value": "Asia/Pontianak" },
      { "name": "Pyongyang", "value": "Asia/Pyongyang" },
      { "name": "Qatar", "value": "Asia/Qatar" },
      { "name": "Qyzylorda", "value": "Asia/Qyzylorda" },
      { "name": "Rangoon", "value": "Asia/Rangoon" },
      { "name": "Riyadh", "value": "Asia/Riyadh" },
      { "name": "Saigon", "value": "Asia/Saigon" },
      { "name": "Sakhalin", "value": "Asia/Sakhalin" },
      { "name": "Samarkand", "value": "Asia/Samarkand" },
      { "name": "Seoul", "value": "Asia/Seoul" },
      { "name": "Shanghai", "value": "Asia/Shanghai" },
      { "name": "Singapore", "value": "Asia/Singapore" },
      { "name": "Taipei", "value": "Asia/Taipei" },
      { "name": "Tashkent", "value": "Asia/Tashkent" },
      { "name": "Tbilisi", "value": "Asia/Tbilisi" },
      { "name": "Tehran", "value": "Asia/Tehran" },
      { "name": "Tel Aviv", "value": "Asia/Tel_Aviv" },
      { "name": "Thimbu", "value": "Asia/Thimbu" },
      { "name": "Thimphu", "value": "Asia/Thimphu" },
      { "name": "Tokyo", "value": "Asia/Tokyo" },
      { "name": "Ujung Pandang", "value": "Asia/Ujung_Pandang" },
      { "name": "Ulaanbaatar", "value": "Asia/Ulaanbaatar" },
      { "name": "Ulan Bator", "value": "Asia/Ulan_Bator" },
      { "name": "Urumqi", "value": "Asia/Urumqi" },
      { "name": "Vientiane", "value": "Asia/Vientiane" },
      { "name": "Vladivostok", "value": "Asia/Vladivostok" },
      { "name": "Yakutsk", "value": "Asia/Yakutsk" },
      { "name": "Yekaterinburg", "value": "Asia/Yekaterinburg" },
      { "name": "Yerevan", "value": "Asia/Yerevan" },
    ],
  },
  {
    "group": "Africa",
    "zones": [
      { "name": "Abidjan", "value": "Africa/Abidjan" },
      { "name": "Accra", "value": "Africa/Accra" },
      { "name": "Addis Ababa", "value": "Africa/Addis_Ababa" },
      { "name": "Algiers", "value": "Africa/Algiers" },
      { "name": "Asmara", "value": "Africa/Asmara" },
      { "name": "Asmera", "value": "Africa/Asmera" },
      { "name": "Bamako", "value": "Africa/Bamako" },
      { "name": "Bangui", "value": "Africa/Bangui" },
      { "name": "Banjul", "value": "Africa/Banjul" },
      { "name": "Bissau", "value": "Africa/Bissau" },
      { "name": "Blantyre", "value": "Africa/Blantyre" },
      { "name": "Brazzaville", "value": "Africa/Brazzaville" },
      { "name": "Bujumbura", "value": "Africa/Bujumbura" },
      { "name": "Cairo", "value": "Africa/Cairo" },
      { "name": "Casablanca", "value": "Africa/Casablanca" },
      { "name": "Ceuta", "value": "Africa/Ceuta" },
      { "name": "Conakry", "value": "Africa/Conakry" },
      { "name": "Dakar", "value": "Africa/Dakar" },
      { "name": "Dar es Salaam", "value": "Africa/Dar_es_Salaam" },
      { "name": "Djibouti", "value": "Africa/Djibouti" },
      { "name": "Douala", "value": "Africa/Douala" },
      { "name": "El Aaiun", "value": "Africa/El_Aaiun" },
      { "name": "Freetown", "value": "Africa/Freetown" },
      { "name": "Gaborone", "value": "Africa/Gaborone" },
      { "name": "Harare", "value": "Africa/Harare" },
      { "name": "Johannesburg", "value": "Africa/Johannesburg" },
      { "name": "Kampala", "value": "Africa/Kampala" },
      { "name": "Khartoum", "value": "Africa/Khartoum" },
      { "name": "Kigali", "value": "Africa/Kigali" },
      { "name": "Kinshasa", "value": "Africa/Kinshasa" },
      { "name": "Lagos", "value": "Africa/Lagos" },
      { "name": "Libreville", "value": "Africa/Libreville" },
      { "name": "Lome", "value": "Africa/Lome" },
      { "name": "Luanda", "value": "Africa/Luanda" },
      { "name": "Lubumbashi", "value": "Africa/Lubumbashi" },
      { "name": "Lusaka", "value": "Africa/Lusaka" },
      { "name": "Malabo", "value": "Africa/Malabo" },
      { "name": "Maputo", "value": "Africa/Maputo" },
      { "name": "Maseru", "value": "Africa/Maseru" },
      { "name": "Mbabane", "value": "Africa/Mbabane" },
      { "name": "Mogadishu", "value": "Africa/Mogadishu" },
      { "name": "Monrovia", "value": "Africa/Monrovia" },
      { "name": "Nairobi", "value": "Africa/Nairobi" },
      { "name": "Ndjamena", "value": "Africa/Ndjamena" },
      { "name": "Niamey", "value": "Africa/Niamey" },
      { "name": "Nouakchott", "value": "Africa/Nouakchott" },
      { "name": "Ouagadougou", "value": "Africa/Ouagadougou" },
      { "name": "Porto-Novo", "value": "Africa/Porto-Novo" },
      { "name": "Sao Tome", "value": "Africa/Sao_Tome" },
      { "name": "Timbuktu", "value": "Africa/Timbuktu" },
      { "name": "Tripoli", "value": "Africa/Tripoli" },
      { "name": "Tunis", "value": "Africa/Tunis" },
      { "name": "Windhoek", "value": "Africa/Windhoek" },
    ],
  },
  {
    "group": "Australia",
    "zones": [
      { "name": "ACT", "value": "Australia/ACT" },
      { "name": "Adelaide", "value": "Australia/Adelaide" },
      { "name": "Brisbane", "value": "Australia/Brisbane" },
      { "name": "Broken Hill", "value": "Australia/Broken_Hill" },
      { "name": "Canberra", "value": "Australia/Canberra" },
      { "name": "Currie", "value": "Australia/Currie" },
      { "name": "Darwin", "value": "Australia/Darwin" },
      { "name": "Eucla", "value": "Australia/Eucla" },
      { "name": "Hobart", "value": "Australia/Hobart" },
      { "name": "LHI", "value": "Australia/LHI" },
      { "name": "Lindeman", "value": "Australia/Lindeman" },
      { "name": "Lord Howe", "value": "Australia/Lord_Howe" },
      { "name": "Melbourne", "value": "Australia/Melbourne" },
      { "name": "North", "value": "Australia/North" },
      { "name": "NSW", "value": "Australia/NSW" },
      { "name": "Perth", "value": "Australia/Perth" },
      { "name": "Queensland", "value": "Australia/Queensland" },
      { "name": "South", "value": "Australia/South" },
      { "name": "Sydney", "value": "Australia/Sydney" },
      { "name": "Tasmania", "value": "Australia/Tasmania" },
      { "name": "Victoria", "value": "Australia/Victoria" },
      { "name": "West", "value": "Australia/West" },
      { "name": "Yancowinna", "value": "Australia/Yancowinna" },
    ],
  },
  {
    "group": "Indian",
    "zones": [
      { "name": "Antananarivo", "value": "Indian/Antananarivo" },
      { "name": "Chagos", "value": "Indian/Chagos" },
      { "name": "Christmas", "value": "Indian/Christmas" },
      { "name": "Cocos", "value": "Indian/Cocos" },
      { "name": "Comoro", "value": "Indian/Comoro" },
      { "name": "Kerguelen", "value": "Indian/Kerguelen" },
      { "name": "Mahe", "value": "Indian/Mahe" },
      { "name": "Maldives", "value": "Indian/Maldives" },
      { "name": "Mauritius", "value": "Indian/Mauritius" },
      { "name": "Mayotte", "value": "Indian/Mayotte" },
      { "name": "Reunion", "value": "Indian/Reunion" },
    ],
  },
  {
    "group": "Atlantic",
    "zones": [
      { "name": "Azores", "value": "Atlantic/Azores" },
      { "name": "Bermuda", "value": "Atlantic/Bermuda" },
      { "name": "Canary", "value": "Atlantic/Canary" },
      { "name": "Cape Verde", "value": "Atlantic/Cape_Verde" },
      { "name": "Faeroe", "value": "Atlantic/Faeroe" },
      { "name": "Faroe", "value": "Atlantic/Faroe" },
      { "name": "Jan Mayen", "value": "Atlantic/Jan_Mayen" },
      { "name": "Madeira", "value": "Atlantic/Madeira" },
      { "name": "Reykjavik", "value": "Atlantic/Reykjavik" },
      { "name": "South Georgia", "value": "Atlantic/South_Georgia" },
      { "name": "Stanley", "value": "Atlantic/Stanley" },
      { "name": "St Helena", "value": "Atlantic/St_Helena" },
    ],
  },
  {
    "group": "Pacific",
    "zones": [
      { "name": "Apia", "value": "Pacific/Apia" },
      { "name": "Auckland", "value": "Pacific/Auckland" },
      { "name": "Chatham", "value": "Pacific/Chatham" },
      { "name": "Easter", "value": "Pacific/Easter" },
      { "name": "Efate", "value": "Pacific/Efate" },
      { "name": "Enderbury", "value": "Pacific/Enderbury" },
      { "name": "Fakaofo", "value": "Pacific/Fakaofo" },
      { "name": "Fiji", "value": "Pacific/Fiji" },
      { "name": "Funafuti", "value": "Pacific/Funafuti" },
      { "name": "Galapagos", "value": "Pacific/Galapagos" },
      { "name": "Gambier", "value": "Pacific/Gambier" },
      { "name": "Guadalcanal", "value": "Pacific/Guadalcanal" },
      { "name": "Guam", "value": "Pacific/Guam" },
      { "name": "Honolulu", "value": "Pacific/Honolulu" },
      { "name": "Johnston", "value": "Pacific/Johnston" },
      { "name": "Kiritimati", "value": "Pacific/Kiritimati" },
      { "name": "Kosrae", "value": "Pacific/Kosrae" },
      { "name": "Kwajalein", "value": "Pacific/Kwajalein" },
      { "name": "Majuro", "value": "Pacific/Majuro" },
      { "name": "Marquesas", "value": "Pacific/Marquesas" },
      { "name": "Midway", "value": "Pacific/Midway" },
      { "name": "Nauru", "value": "Pacific/Nauru" },
      { "name": "Niue", "value": "Pacific/Niue" },
      { "name": "Norfolk", "value": "Pacific/Norfolk" },
      { "name": "Noumea", "value": "Pacific/Noumea" },
      { "name": "Pago Pago", "value": "Pacific/Pago_Pago" },
      { "name": "Palau", "value": "Pacific/Palau" },
      { "name": "Pitcairn", "value": "Pacific/Pitcairn" },
      { "name": "Ponape", "value": "Pacific/Ponape" },
      { "name": "Port Moresby", "value": "Pacific/Port_Moresby" },
      { "name": "Rarotonga", "value": "Pacific/Rarotonga" },
      { "name": "Saipan", "value": "Pacific/Saipan" },
      { "name": "Samoa", "value": "Pacific/Samoa" },
      { "name": "Tahiti", "value": "Pacific/Tahiti" },
      { "name": "Tarawa", "value": "Pacific/Tarawa" },
      { "name": "Tongatapu", "value": "Pacific/Tongatapu" },
      { "name": "Truk", "value": "Pacific/Truk" },
      { "name": "Wake", "value": "Pacific/Wake" },
      { "name": "Wallis", "value": "Pacific/Wallis" },
      { "name": "Yap", "value": "Pacific/Yap" },
    ],
  },
  {
    "group": "Antarctica",
    "zones": [
      { "name": "Casey", "value": "Antarctica/Casey" },
      { "name": "Davis", "value": "Antarctica/Davis" },
      { "name": "DumontDUrville", "value": "Antarctica/DumontDUrville" },
      { "name": "Macquarie", "value": "Antarctica/Macquarie" },
      { "name": "Mawson", "value": "Antarctica/Mawson" },
      { "name": "McMurdo", "value": "Antarctica/McMurdo" },
      { "name": "Palmer", "value": "Antarctica/Palmer" },
      { "name": "Rothera", "value": "Antarctica/Rothera" },
      { "name": "South Pole", "value": "Antarctica/South_Pole" },
      { "name": "Syowa", "value": "Antarctica/Syowa" },
      { "name": "Vostok", "value": "Antarctica/Vostok" },
    ],
  },
  {
    "group": "Arctic",
    "zones": [
      { "name": "Longyearbyen", "value": "Arctic/Longyearbyen" },
    ],
  },
  {
    "group": "UTC",
    "zones": [
      { "name": "UTC", "value": "UTC" },
    ],
  },
  {
    "group": "Manual Offsets",
    "zones": [
      { "name": "UTC-12", "value": "UTC-12" },
      { "name": "UTC-11", "value": "UTC-11" },
      { "name": "UTC-10", "value": "UTC-10" },
      { "name": "UTC-9", "value": "UTC-9" },
      { "name": "UTC-8", "value": "UTC-8" },
      { "name": "UTC-7", "value": "UTC-7" },
      { "name": "UTC-6", "value": "UTC-6" },
      { "name": "UTC-5", "value": "UTC-5" },
      { "name": "UTC-4", "value": "UTC-4" },
      { "name": "UTC-3", "value": "UTC-3" },
      { "name": "UTC-2", "value": "UTC-2" },
      { "name": "UTC-1", "value": "UTC-1" },
      { "name": "UTC+0", "value": "UTC+0" },
      { "name": "UTC+1", "value": "UTC+1" },
      { "name": "UTC+2", "value": "UTC+2" },
      { "name": "UTC+3", "value": "UTC+3" },
      { "name": "UTC+4", "value": "UTC+4" },
      { "name": "UTC+5", "value": "UTC+5" },
      { "name": "UTC+6", "value": "UTC+6" },
      { "name": "UTC+7", "value": "UTC+7" },
      { "name": "UTC+8", "value": "UTC+8" },
      { "name": "UTC+9", "value": "UTC+9" },
      { "name": "UTC+10", "value": "UTC+10" },
      { "name": "UTC+11", "value": "UTC+11" },
      { "name": "UTC+12", "value": "UTC+12" },
      { "name": "UTC+13", "value": "UTC+13" },
      { "name": "UTC+14", "value": "UTC+14" },
    ],
  },
];

export default timezoneList;

export const DEFAULT_TIMEZONE = 'America/New_York';