import _ from 'lodash';
import TIMEZONE_DEFINITIONS, { DEFAULT_TIMEZONE } from '@typings/TimeZonesList';
import CustomSelect from '@components/FormFields/CustomSelect';

type OptionGroups = {
  key?: string;
  title: string;
  options: Array<{
    key?: string;
    title: string;
    value: string;
    disabled?: boolean;
  }>;
};

const TIMEZONE_OPTIONS = TIMEZONE_DEFINITIONS.reduce<Array<OptionGroups>>((acc, group) =>
  [
    ...acc,
    {
      key: group.group,
      options: group.zones.map((timezone) => ({
        title: timezone.name,
        value: timezone.value,
      })),
      title: group.group,
    },
  ],
[],
);

type Props = {
  id: string;
  onChange: NonNullable<Parameters<typeof CustomSelect>>[0]['onChange'];
  value: string;
};

const TimezonePicker: React.FC<Props> = ({
  id, onChange, value,
}) => (
  <CustomSelect
    id={id}
    label={'Timezone'}
    onChange={onChange}
    optionsWithGroups={TIMEZONE_OPTIONS}
    value={value}
    withFilter
  />
);

export default TimezonePicker;
export { DEFAULT_TIMEZONE };
