import { useEffect, useState } from 'react';
import countryToCurrency from 'country-to-currency';
import useIPGeolocation from '@hooks/useIPGeolocation';
import { logError } from '@providers/ErrorTracking';
import { DEFAULT_CURRENCY } from '@typings/common';

type IsoCode = keyof typeof countryToCurrency;

const FORCED_MAPPING: Record<string, string> = {
  // Kosovo, should maybe be EUR, but stay with USD for now.
  GIP: 'GBP', // The gibralter pound is pegged to the british pound.
  XK: DEFAULT_CURRENCY, // Kosovo, should maybe be EUR, but stay with USD for now.
};

const isValidIsoCode = (isoCode: string): isoCode is IsoCode => Boolean(countryToCurrency[isoCode as IsoCode]);

const useLocalCurrency = (): string | null => {
  const [currency, setCurrency] = useState<string | null>(null);
  const [geolocation] = useIPGeolocation();
  useEffect(() => {
    const getData = async (): Promise<void> => {
      const countryCode = geolocation?.country?.iso_code;
      if (countryCode) {
        if (FORCED_MAPPING[countryCode]) {
          setCurrency(FORCED_MAPPING[countryCode]);
        } else if (isValidIsoCode(countryCode)) {
          setCurrency(countryToCurrency[countryCode]);
        } else {
          logError(new Error('Unsupported countryCode'), { countryCode });
          setCurrency(DEFAULT_CURRENCY);
        }
      } else {
        setCurrency(DEFAULT_CURRENCY);
      }
    };
    getData();
  }, [geolocation]);
  return currency;
};

export default useLocalCurrency;
