import Axios from 'axios';
import { logError, ErrorFlow } from '@providers/ErrorTracking';
import { BaseFunction } from '@typings/utility';
import ApiError from './ApiError';

type RequestWithCatchWrapped<F extends BaseFunction> = (...args: Parameters<F>) => Promise<
 | { error: null; data: Awaited<ReturnType<F>> }
 | { error: ApiError; data: null }
>;

type Options = {
  errorFlow?: ErrorFlow;
};

const requestWithCatch = <F extends BaseFunction>(fn: F, options: Options = {}): RequestWithCatchWrapped<F> => async (...args) => {
  const requestingPage = window?.location?.href || 'unknown page';
  try {
    const data = await fn(...args);
    return { data, error: null };
  } catch(error) {
    const asApiError = ApiError.create(error);
    const errorData = asApiError?.data;
    const errors = asApiError?.errors || [];
    const errorDataObject = typeof errorData === 'string' ? { data: errorData } : errorData;
    const axiosErrorContext = Axios.isAxiosError(error)
      ? {
        baseUrl: error.config?.baseURL,
        method: error.config?.method,
        url: error.config?.url,
      }
      : {};
    const functionName = fn.name || `${fn}`;
    const errorType = asApiError?.type || 'UnknownError';
    const errorMessage = asApiError?.message || 'UnknownError';
    const tags = options?.errorFlow ? undefined : { flow: options.errorFlow };

    logError(
      asApiError,
      {
        errorMessage,
        errorType,
        errors,
        functionName,
        handledBy: 'requestWithCatch',
        requestingPage,
        tags,
        ...errorDataObject,
        ...axiosErrorContext,
      },
    );
    return { data: null, error: asApiError };
  }
};

export default requestWithCatch;
