import { createContext } from 'react';
import { NoodleProductTypes } from '@typings/graphql-models';
import { getCreatorProducts } from '@tsClient';
import getCreatorProfile from '@tsClient/creators/getCreatorProfile';

type Creator = Awaited<ReturnType<typeof getCreatorProfile>>;

export type OnboardingState = {
  creator: Creator | null;
  isFetchingProducts: boolean;
  refetchProducts: () => Promise<Awaited<ReturnType<typeof getCreatorProducts>>['products']>;
  products: Awaited<ReturnType<typeof getCreatorProducts>>['products'];
  setActiveProductType: (t: NoodleProductTypes | null) => void;
  setNextAction: (a: (() => void) | null) => void;
  setBackButtonAction: (a: (() => void) | null) => void;
  handleCompleteOnboarding: () => Promise<void>;
};

const OnboardingContext = createContext<OnboardingState>({
  creator: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCompleteOnboarding: async () => {},
  isFetchingProducts: false,
  products: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchProducts: async () => [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActiveProductType: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBackButtonAction: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNextAction: () => {},
});

export default OnboardingContext;
