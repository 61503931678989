import ChatsCircle from '@components/Icons/ChatsCircle';
import UsersThree from '@components/Icons/UsersThree';
import Handshake from '@components/Icons/Handshake';
import Globe from '@components/Icons/Globe';
import File from '@components/Icons/File';
import PDFIcon from '@components/Icons/PDFIcon';
import Cookie from '@components/Icons/Cookie';
import Image from '@components/Icons/Image';
import * as Types from '@typings/graphql-models';
import MimeTypes from '@typings/MimeTypes';
import type { Icon } from '@phosphor-icons/react';
import { NoodleProductTypes } from '@typings/api-models';
import CalendarBlank from '@components/Icons/CalendarBlank';
import s from './ProductIcon.module.scss';

type Props = {
  noodleProductType?: NoodleProductTypes | null | undefined;
  size: number;
  mimeType?: Types.Maybe<string> | undefined;
  showLabel?: boolean;
};

export const pickProductIcon = (
  noodleProductType: NoodleProductTypes | null | undefined,
  mimeType?: Types.Maybe<string> | undefined,
): Icon | null => {
  if (
    noodleProductType === NoodleProductTypes.Subscription
  ) {
    return UsersThree;
  }

  if (noodleProductType === NoodleProductTypes.Lite || noodleProductType === NoodleProductTypes.Response) {
    return ChatsCircle;
  }

  if (noodleProductType === NoodleProductTypes.InPersonSession) {
    return Handshake;
  }

  if (noodleProductType === NoodleProductTypes.OnlineSession) {
    return Globe;
  }

  if (noodleProductType === NoodleProductTypes.Handbook) {
    if (mimeType) {
      if (mimeType === MimeTypes.PDF) {
        return PDFIcon;
      }
      if (mimeType === MimeTypes.AVI) {
        return Cookie;
      }
      if (mimeType.includes('image')) {
        return Image;
      }
    }
    return File;
  }

  if (noodleProductType === NoodleProductTypes.Consultation) {
    return CalendarBlank;
  }
  return null;
};

const pickLabel = (noodleProductType: NoodleProductTypes | null | undefined): string => {
  if (noodleProductType === NoodleProductTypes.Lite) {
    return 'Private Chat';
  }
  if (noodleProductType === NoodleProductTypes.OnlineSession) {
    return 'Remote';
  }
  if (noodleProductType === NoodleProductTypes.InPersonSession) {
    return 'In-Person';
  }
  return '';
};

const ProductIcon: React.FC<Props> = ({ noodleProductType, showLabel, size, mimeType }) => {
  const Component = pickProductIcon(noodleProductType, mimeType);
  const label = showLabel && pickLabel(noodleProductType);
  if (!Component) {
    return null;
  }
  return (
    <>
      <Component size={size} weight="fill" className={s.icons} />
      {label}
    </>
  );
};

export default ProductIcon;
